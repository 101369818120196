<template>
<section id="dashboard-analytics">
    <b-row class="match-height">
        <b-col lg="6" md="12">
            <statistic-card-with-area-chart v-if="data.accountConsumption" icon="ListIcon" :loader="isLoadingAccount" :buttonShow='showButton' color="success" :statisticCurrency="data.accountConsumption.analyticsData.currency" :statistic="data.accountConsumption.analyticsData.balance | formatNumber" :statisticThreshold="data.accountConsumption.analyticsData.alert_threshold | formatNumber" :statisticBonus="data.accountConsumption.analyticsData.bonus | formatNumber" statistic-title="Account Balance" :chart-data="data.accountConsumption.series" />
        </b-col>
        <b-col lg="3" sm="6">
            <statistic-card-with-area-chart v-if="data.subscribersGained" icon="UsersIcon" :loader="isLoading"  :statistic="data.subscribersGained.analyticsData.subscribers | formatNumber" statistic-title="Total Contacts" :chart-data="data.subscribersGained.series" />
        </b-col>
        <b-col lg="3" sm="6">
            <statistic-card-with-area-chart v-if="data.messageSent" icon="PackageIcon" :loader="isLoading" color="warning" :statistic="data.messageSent.analyticsData.orders | formatNumber" statistic-title="Total Message Sent" :chart-data="data.messageSent.series" />
        </b-col>
    </b-row>

    <b-row class="match-height">
        <b-col lg="12">
            <analytics-avg-sessions :data="avgMessages" />
        </b-col>
    </b-row>
    <b-row class="match-height hidden">
        <b-col lg="12">
            <echart-stacked-area />
        </b-col>
    </b-row>
    <b-row class="match-height hidden">
        <b-col lg="12">
            <echart-area />
        </b-col>

    </b-row>

</section>
</template>

<script>
import {
    BRow,
    BCol,
} from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EchartStackedArea from './EchartAirtimeStackedArea.vue'
import EchartArea from './EchartPaymentArea.vue'
import {
    kFormatter
} from '@core/utils/filter'
import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
import AnalyticsTimeline from './AnalyticsTimeline.vue'
import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'
import AnalyticsAppDesign from './AnalyticsAppDesign.vue'
import store from '@/store/index'
import $ from 'jquery'
import moment from "moment";
import numeral from "numeral";

export default {
    components: {
        BRow,
        BCol,
        AnalyticsAvgSessions,
        StatisticCardWithAreaChart,
        AnalyticsSupportTracker,
        AnalyticsTimeline,
        AnalyticsSalesRadarChart,
        AnalyticsAppDesign,
        EchartStackedArea,
        EchartArea
    },
    data() {
        return {
            data: {
                congratulations: {
                    name: 'John',
                    saleToday: '57.6',
                },
                accountConsumption: {
                    series: [{
                        name: 'Consumption',
                        data: [908, 600, 2003, 800, 508, 200, 1200],
                    }, ],
                    analyticsData: {
                        balance: 0,
                        alert_threshold: 0,
                        currency: ""
                    },
                },
                subscribersGained: {
                    series: [{
                        name: 'Contacts',
                        data: [],
                    }, ],
                    analyticsData: {
                        subscribers: 0,
                    },
                },
                messageSent: {
                    series: [{
                        name: 'Message',
                        data: [],
                    }, ],
                    analyticsData: {
                        orders: 0,
                    },
                },
                avgMessages: {
                    recipients: 0,
                    percent: '',
                    delivered: 0,
                    failed: 0,
                    pending: 0,
                    sent: 0,
                    salesBar: {
                        series: [{
                            name: 'SMS Delivered',
                            data: [],
                        }, ],
                    },
                },
                supportTracker: {
                    title: 'Support Tracker',
                    lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
                    totalTicket: 163,
                    newTicket: 29,
                    openTicket: 63,
                    responseTime: 1,
                    supportTrackerRadialBar: {
                        series: [83],
                    },
                },
                timeline: {
                    step1: {
                        title: '12 Invoices have been paid',
                        subtitle: 'Invoices have been paid to the company.',
                        img: require('@/assets/images/icons/json.png'),
                        fileName: 'data.json',
                        duration: '12 min ago',
                    },
                    step2: {
                        title: 'Client Meeting',
                        subtitle: 'Project meeting with john @10:15am',
                        avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
                        avatarName: 'John Doe (Client)',
                        occupation: 'CEO of Infibeam',
                        duration: '45 min ago',
                    },
                    step3: {
                        title: 'Create a new project for client',
                        subtitle: 'Add files to new design folder',
                        duration: '2 day ago',
                        avatars: [{
                                userImg: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
                                name: 'Billy Hopkins'
                            },
                            {
                                userImg: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
                                name: 'Amy Carson'
                            },
                            {
                                userImg: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
                                name: 'Brandon Miles'
                            },
                            {
                                userImg: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
                                name: 'Daisy Weber'
                            },
                            {
                                userImg: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
                                name: 'Jenny Looper'
                            },
                        ],
                    },
                    step4: {
                        title: 'Create a new project for client',
                        duration: '5 day ago',
                        subtitle: 'Add files to new design folder',
                    },
                },
                salesChart: {
                    series: [{
                            name: 'Sales',
                            data: [90, 50, 86, 40, 100, 20],
                        },
                        {
                            name: 'Visit',
                            data: [70, 75, 70, 76, 20, 85],
                        },
                    ],
                },
                appDesign: {
                    date: '03 Sep, 20',
                    title: 'App design',
                    subtitle: 'You can Find Only Post and Quotes Related to IOS like ipad app design, iphone app design',
                    teams: [{
                            name: 'Figma',
                            color: 'light-warning'
                        },
                        {
                            name: 'Wireframe',
                            color: 'light-primary'
                        },
                    ],
                    members: [{
                            img: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
                            color: 'primary'
                        },
                        {
                            text: 'PI',
                            color: 'light-danger'
                        },
                        {
                            img: require('@/assets/images/portrait/small/avatar-s-14.jpg'),
                            color: 'primary'
                        },
                        {
                            img: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
                            color: 'primary'
                        },
                        {
                            text: 'AL',
                            color: 'light-secondary'
                        },
                    ],
                    planing: [{
                            title: 'Due Date',
                            subtitle: '12 Apr, 21'
                        },
                        {
                            title: 'Budget',
                            subtitle: '$49251.91'
                        },
                        {
                            title: 'Cost',
                            subtitle: '$840.99'
                        },
                    ],
                },

            },
            avgMessages: {
                recipients: 0,
                percent: '',
                startDate: "",
                endDate: "",
                delivered: 0,
                failed: 0,
                sent: 0,
                salesBar: {
                    series: [],
                },
            },
            isLoading:false,
            isLoadingAccount: false,
            showButton:true

        }
    },
    mounted() {
        this.endDate = moment().format("YYYY-MM-DD")
        this.startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');

        this.getSummaryData()
        this.getLastMessage()
        this.getSummaryList()
    },

    methods: {
        kFormatter,
        getSummaryData() {
            let vm = this
            vm.isLoadingAccount = true

            $.get({
                url: store.state.rootUrl + 'account/v1/view/wallet',
                type: "get",
                async: true,
                data: {
                    clientId: vm.$cookies.get("clientData").clientId
                },
                crossDomain: true,
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                },
                success: function (response, status, jQxhr) {
                    vm.isLoadingAccount = false
                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {
                        vm.data.accountConsumption.analyticsData.balance = 0
                        vm.data.accountConsumption.analyticsData.alert_threshold = 0
                    } else {
                        let result = response.data.data;
                        vm.data.accountConsumption.analyticsData.balance = result.balance
                        vm.data.accountConsumption.analyticsData.bonus = result.bonus
                        vm.data.accountConsumption.analyticsData.currency = result.currency
                        vm.data.accountConsumption.analyticsData.alert_threshold = result.alert_threshold

                    }

                },
                error: function (jQxhr, status, error) {
                    vm.isLoadingAccount = false
                    vm.rows = []
                    console.log(jQxhr.responseJSON.data.code)
                    if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                        vm.$cookies.remove("token");
                        vm.$cookies.remove("clientData");
                        // Redirect to login page
                        vm.$router.push({ name: 'login' })
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: jQxhr.responseJSON.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                    }

                }
            });

        },
        getLastMessage() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/bulk_usage',
                type: "get",
                async: true,
                data: {
                    limit: 1,
                    status: 400,
                },
                crossDomain: true,
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                success: function (response, status, jQxhr) {

                    if (response.data.code != 200) {
                        vm.$swal({
                            title: 'Error!',
                            text: response.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    } else {
                        var smsData = response.data.data.data[0];
                        vm.avgMessages.recipients = smsData.recipients
                        vm.avgMessages.percent = Number(smsData.delivery_percent).toFixed(2)
                        vm.avgMessages.delivered = smsData.delivered
                        vm.avgMessages.failed = smsData.failed
                        vm.avgMessages.sent = smsData.sent
                        vm.avgMessages.pending = smsData.pending
                        vm.avgMessages.salesBar.series.push({
                            name: 'SMS Delivered',
                            data: [smsData.delivered],
                        })

                        //console.log(JSON.stringify(vm.data.avgMessages))
                    }
                },
                error: function (jQxhr, status, error) {

                }
            });
        },
        getSummaryList() {
            let vm = this
            vm.isLoading =  true
            $.get({
                url: store.state.rootUrl + 'account/v1/view/dashboard_stats',
                type: "get",
                async: true,
                data: {
                    start: vm.startDate,
                    end: vm.endDate,
                },
                crossDomain: true,
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                success: function (response, status, jQxhr) {
                    vm.isLoading =  false

                    if (response.data.code == 200) {
                        vm.data.subscribersGained.analyticsData.subscribers = response.data.data.contacts
                        vm.data.messageSent.analyticsData.orders = response.data.data.sms_sent

                    }
                },
                error: function (jQxhr, status, error) {
                    vm.isLoading =  false

                }
            });
        }
    },
    filters: {
        formatNumber(value) {
            if (!value) return 0
            return numeral(value).format("0,0");
        },

    }
}
</script>
