<template>
<b-card v-if="data" label="Last Message Sent">
    <b-row class="pb-50">

        <!-- text and button -->
        <b-col sm="6" order-sm="1" order="2" class="d-flex justify-content-between flex-column mt-1 mt-sm-0">
            <div>
                <h2 class="font-weight-bolder mb-25">
                    {{ data.sent | formatNumber}}
                </h2>
                <b-card-text class="font-weight-bold mb-2">
                    Stats for Last Message Sent
                </b-card-text>

                <h5 class="font-medium-2">
                    <span class="text-success mr-50">{{ data.percent }}</span>
                    <span>Delivery Rate</span>
                </h5>
            </div>

            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="shadow" @click="goToMessage()">
                <span>View Details </span>
                <feather-icon icon="ChevronsRightIcon" />
            </b-button>
        </b-col>

        <!-- dropdown and chart -->
        <b-col sm="6" cols="12" order-sm="2" order="1" class="d-flex justify-content-between flex-column text-right">

            <!-- apex chart -->
            <vue-apex-charts type="bar" height="200" :options="salesBar.chartOptions" :series="data.salesBar.series" />
        </b-col>
    </b-row>
    <hr>

    <!-- progress bar -->
    <b-row class="avg-sessions pt-50">
        <b-col cols="6" class="mb-2">
            <b-card-text class="mb-50">
                Sent: {{ data.delivered | formatNumber }}
            </b-card-text>
            <b-progress :value="data.delivered" :max="data.sent" height="6px" variant="success" />
        </b-col>
        <b-col cols="6">
            <b-card-text class="mb-50">
                Failed: {{ data.failed | formatNumber}}
            </b-card-text>
            <b-progress :value="data.failed" :max="data.sent" variant="danger" height="6px" class="mt-25" />
        </b-col>
        <b-col cols="12">
            <b-card-text class="mb-50">
                Pending: {{ data.pending | formatNumber}}
            </b-card-text>
            <b-progress :value="data.pending" :max="data.sent" variant="warning" height="6px" class="mt-25" />
        </b-col>
    </b-row>
</b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BProgress,
    BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import {
    $themeColors
} from '@themeConfig'
import {
    kFormatter
} from '@core/utils/filter'
import numeral from "numeral";
export default {
    components: {
        VueApexCharts,
        BCard,
        BRow,
        BCol,
        BButton,
        BCardText,
        BDropdown,
        BDropdownItem,
        BProgress,
    },
    directives: {
        Ripple,
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            salesBar: {
                chartOptions: {
                    chart: {
                        sparkline: {
                            enabled: true
                        },
                        toolbar: {
                            show: false
                        },
                    },
                    grid: {
                        show: false,
                        padding: {
                            left: 0,
                            right: 0,
                        },
                    },
                    states: {
                        hover: {
                            filter: 'none',
                        },
                    },
                    colors: [
                        '#5cb85c',
                    ],
                    plotOptions: {
                        bar: {
                            columnWidth: '45%',
                            distributed: true,
                            endingShape: 'rounded',
                        },
                    },
                    tooltip: {
                        x: {
                            show: false
                        },
                    },
                    xaxis: {
                        type: 'numeric',
                    },
                },
            },
        }
    },
    methods: {
        kFormatter,
        goToMessage() {
            this.$router.push({
                name: 'bulk-message'
            });
        }

    },
    filters: {
         formatNumber(value){
             if(!value) return 0
            return numeral(value).format("0,0");
        },
        
    }
}
</script>
